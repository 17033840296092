<template>
  <section class="hero-slider-business mb-5">
    <swiper :space-between="0" :breakpoints="swiperOptions" :autoHeight="false" :autoplay="true">
      <swiper-slide v-for="slide in slides" :key="slide.id">
        <!-- 
        code for backup
        :style="{ backgroundImage: 'url(' + slide.image + ')' }" -->
        <div class="single-slide">
          <div class="overlay"></div>

          <div class="main-hero-image">
            <!-- <img src="/img/slide-mobile.png" alt="" class="sm-slide-img" /> -->
            <img :src="slide.image" alt="" class="lg-slide-img" />
            <!-- Image below will appear in smaller devices from 768px in width -->
            <!-- Please add some image in public folder to test for smaller image in smaller devices -->
          </div>
          <div class="slider-content-wrapper">
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <div v-show="slide.viewTagButton">
                     <span class="tagline py-1 px-2 mb-4 d-inline-flex" >{{
                         slide.tag
                       }}</span>
                  </div>
                  <div class="slide-content">
                    <h1 v-html="slide.title"></h1>
                    <p>{{ slide.description }}</p>
                  </div>
                  <div class="slide-action" v-show="slide.viewDetailButton">
                    <a
                      :href="slide.url"
                      class="btn btn-primary px-3 py-2"
                      target="_blank"
                      >View Detail</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";
import UrlConfig from "@/config/UrlConfig";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "BusinessHomeSlider",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      slides: ref([]),
    };
  },

  mounted() {
    this.getSlider();
  },

  methods: {
    async getSlider() {
      const url = UrlConfig.urlHelper.hostUrl + "/banner-list";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let aboutBannerData = response.data.data;
          if (response.data.status_code === 200 && aboutBannerData.length > 0) {
            Object.entries(aboutBannerData).forEach(([key, value]) => {
              this.shopPageBannerImage = value.image_path
              this.slides.push({
                id: ++key,
                title: value.title,
                description: value.description,
                tag: value.sub_title,
                image: value.image_path,
                viewTagButton: value.sub_title ? true : false,
                url: value.link,
                viewDetailButton: value.url ? true : false,
              });
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style scoped></style>
